body {
  margin: 0;
  font-family: 'DMSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #191B23;
  scrollbar-width: thin;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
 font-family: 'DMSans';
 src: url('./fonts/DMSans-Regular.ttf') format('opentype');
 font-display: swap;
}

@font-face {
 font-family: 'Visby';
 src: url('./fonts/Visby/VisbyRegular.otf') format('opentype');
 font-display: swap;
}

@font-face {
 font-family: 'DMSansBold';
 src: url('./fonts/DMSans-Bold.ttf') format('opentype');
 font-display: swap;
}

@font-face {
 font-family: 'VisbyBold';
 src: url('./fonts/Visby/VisbyBold.otf') format('opentype');
 font-display: swap;
}

 a {
   color: white;
   text-decoration: none;
 }

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #191B23;
}

body::-webkit-scrollbar-thumb {
  background-color: #5E5F65;
  border: 2px solid #191B23;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #2c3041;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #12141A;
  border-radius: 20px;
  border-left: 1.5px solid #2c3041;
}
